<template>
  <div class="header-nav">
    <button class="menu-toggle" @click="toggleMenu">
      ☰ Menu
    </button>
    <nav class="menu" :class="{ 'menu-open': menuOpen }">
      <div class="menu-links">
        <router-link
          to="/dashboard"
          class="menu-item"
          :class="{ active: isActive('/dashboard') }"
          @click="closeMenu"
        >DASHBOARD</router-link>
        <router-link
          to="/customers"
          class="menu-item"
          :class="{ active: isActive('/customers') }"
          @click="closeMenu"
        >CLIENTES</router-link>
        <router-link
          to="/task1"
          class="menu-item"
          :class="{ active: isActive('/task1') }"
          @click="closeMenu"
        >TAREAS</router-link>
        <router-link
          to="/reminder1"
          class="menu-item"
          :class="{ active: isActive('/reminder1') }"
          @click="closeMenu"
        >RECORDATORIOS</router-link>
        <router-link
          to="/revision"
          class="menu-item"
          :class="{ active: isActive('/revision') }"
          @click="closeMenu"
        >REVISIÓN</router-link>
        <router-link
          to="/opportunities"
          class="menu-item"
          :class="{ active: isActive('/opportunities') }"
          @click="closeMenu"
        >OPORTUNIDADES</router-link>
        <router-link
          v-if="userStore?.getUser?.admin"
          to="/users"
          class="menu-item"
          :class="{ active: isActive('/users') }"
          @click="closeMenu"
        >Usuarios</router-link>
      </div>
      <div class="dropdown-container">
        <AppHeaderDropdownAccnt />
      </div>
    </nav>
  </div>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { useUserStore } from '@/plugins/store';

export default {
  name: 'AppHeaderNav',
  data() {
    return {
      menuOpen: false,
    };
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  mounted(){
    this.user = this.userStore.getUser
  },
  methods: {
    isActive(path) {
      return this.$route && this.$route.path === path;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
  components: {
    AppHeaderDropdownAccnt,
  },
};
</script>

<style scoped>
.header-nav {
  background-color: #003d4d;
  padding: 10px;
  position: relative;
}

/* Botón para dispositivos móviles */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 10px;
}

.menu-item {
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 5px;
}

.menu-item:hover {
  background-color: #007bff;
}

.menu-item.active {
  background-color: white;
  color: #003d4d;
}
.menu {
  display: flex;
  align-items: center;
  width: 100%;
  
}

.menu-links {
  display: flex;
  gap: 15px;
  flex-grow: 1; /* Esto hace que ocupe el espacio disponible */
}

.dropdown-container {
  margin-left: auto; /* Empuja el dropdown al final de la barra */
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    align-items: flex-start;
    display: none; /* Oculta el menú por defecto en móviles */
    gap: 5px;
    background-color: #003d4d; /* Fondo para el menú desplegable */
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    
  }

  .menu.menu-open {
    display: flex; /* Muestra el menú cuando se abre en móviles */
  }

  .menu-toggle {
    display: block; /* Muestra el botón de menú en móviles */
  }

  .menu-item {
    width: 100%; /* Hace que los enlaces del menú ocupen el ancho completo */
    padding: 10px 15px;
    border-radius: 0;
  }
}

</style>
